<template>
  <div>
    <v-toolbar flat>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-toolbar-title>
        Player
        <span class="yellow--text">{{
          this.$store.state.lobbies.players | formatMoney
        }}</span>
      </v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2">Sync</v-btn>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-btn color="primary" dark @click="syncMode" class="mb-2"
        >Initial Data Save</v-btn
      >
      <!-- <v-btn
        color="primary"
        dark
        style="margin-left:10px"
        @click="
          getBotsItem(
            {
              minBots: $store.state.lobbies.all[0].data[0].minBots,
              maxBots: $store.state.lobbies.all[0].data[0].maxBots,
              taxMax: $store.state.lobbies.all[0].data[0].taxMax,
              taxRatio: $store.state.lobbies.all[0].data[0].taxRatio,
            },
            'all'
          )
        "
        class="mb-2"
      >
        All Limit Change</v-btn
      > -->
    </v-toolbar>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(item, i) in this.$store.state.lobbies.all"
        :key="i"
      >
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-btn color="primary" dark @click="popupMode(item)" class="mb-2"
              >Edit</v-btn
            >
            <v-icon color="primary">
              $expand
            </v-icon>
          </template>
          <v-toolbar flat :color="setColor(item.title)" dark>
            <v-toolbar-title>
              {{ item.title }}

              <span class="yellow--text"
                >Player: {{ item.players | formatMoney }}</span
              >
            </v-toolbar-title>
          </v-toolbar>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="item.data"
                :items-per-page="50"
                class="elevation-1"
              >
                <template v-slot:item.action="{ item }">
                  <v-icon small class="mr-2" @click="getItem(item)"
                    >edit</v-icon
                  >
                </template>
                <template v-slot:item.enable="{ item }">
                  <v-switch
                    v-model="item.enable"
                    @change="changeEnabled(item)"
                  ></v-switch>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="center">
      <v-dialog v-model="dialog1" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline"> Update</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-switch v-model="currentLobbyItem.enable"></v-switch>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="TaxRatio"
                    placeholder="TaxRatio"
                    v-model="currentLobbyItem.taxRatio"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="WinRatio"
                    placeholder="WinRatio"
                    v-model="currentLobbyItem.winRatio"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="SpecialCardRatio"
                    placeholder="SpecialCardRatio"
                    v-model="currentLobbyItem.specialCardRatio"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="SpecialCardType"
                    placeholder="SpecialCardType"
                    v-model="currentLobbyItem.specialCardType"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="JackpotRatio"
                    placeholder="jackpotRatio"
                    v-model="currentLobbyItem.jackpotRatio"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog1 = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveItem">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog2" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline"> Update</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="TaxRatio"
                    placeholder="TaxRatio"
                    v-model="currentLobbyItem.taxRatio"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="WinRatio"
                    placeholder="WinRatio"
                    v-model="currentLobbyItem.winRatio"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="SpecialCardRatio"
                    placeholder="SpecialCardRatio"
                    v-model="currentLobbyItem.specialCardRatio"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="JackpotRatio"
                    placeholder="jackpotRatio"
                    v-model="currentLobbyItem.jackpotRatio"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog2 = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveModeItem">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
// emerald yeşi // ruby kırmızı // sapphire mavi // onyx sarı // amethys mor
<script>
export default {
  data() {
    return {
      dialog1: false,
      dialog2: false,
      headers: [
        {
          text: "id",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "isElite", value: "isElite" },
        { text: "stake", value: "stake" },
        { text: "taxRatio", value: "taxRatio" },
        { text: "winRatio", value: "winRatio" },
        { text: "maxPlayers", value: "maxPlayers" },
        { text: "jackpotRatio", value: "jackpotRatio" },
        { text: "specialCardRatio", value: "specialCardRatio" },
        { text: "specialCardType", value: "specialCardType" },
        { text: "enable", value: "enable" },
        { text: "action", value: "action" },
      ],
      currentLobbyItem: {},
    };
  },
  methods: {
    getItem(lobbyItem) {
      this.currentLobbyItem = lobbyItem;
      this.dialog1 = true;
    },
    popupMode(lobbyItem) {
      this.currentLobbyItem = lobbyItem.data[0];
      this.dialog2 = true;
    },

    async saveItem() {
      await this.$store.dispatch(
        "lobbies/changeEnabled",
        this.currentLobbyItem
      );
      this.dialog1 = false;
    },
    async saveModeItem() {
      await this.$store.dispatch("lobbies/modesChanged", this.currentLobbyItem);
      this.dialog2 = false;
      this.initialize();
    },
    setColor(type) {
      switch (type) {
        case "Emerald":
          return "green darken-4";
        case "Ruby":
          return "red darken-4";
        case "Sapphire":
          return "blue darken-4";
        case "Onyx":
          return "yellow darken-2";
        case "Amethyst":
          return "#952175";
      }
    },
    async changeEnabled(val) {
      await this.$store.dispatch("lobbies/changeEnabled", val);
    },
    async syncMode() {
      await this.$store.dispatch("lobbies/syncMode");
    },

    async initialize() {
      await this.$store.dispatch("lobbies/getLobbies");
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>
